/* ===================== */
/* ==== Botão close ==== */
/* ===================== */
.swal2-container.swal2-center {
  z-index: 300000000;
}
.btn-close-alert {
  position: absolute;
  top: 20px;
  right: 30px;
}
.btn-close-alert::after {
  content: "";
  position: absolute;
  width: 4px;
  border-radius: 4px;
  height: 20px;
  transform: rotate(45deg);
  background: #005d89;
}
.btn-close-alert::before {
  content: "";
  position: absolute;
  width: 4px;
  border-radius: 4px;
  height: 20px;
  transform: rotate(-45deg);
  background: #005d89;
}
/* =========================== */
/* ===== Box and content ===== */
/* =========================== */
.swal2-header.header-alert {
  display: flex;
  flex-direction: row;
  max-width: max-content;
}
.swal2-content.description-alert {
  text-align: left;
  width: 100%;
}

@media (max-width: 800px) {
  .swal2-header {
    padding: 0;
  }
  .swal2-header.header-alert {
    max-width: inherit;
    flex-direction: column;
  }
  .swal2-content.description-alert {
    text-align: center;
    width: 100%;
  }
}
/* =============== */
/* ==== Ícone ==== */
/* =============== */
.swal2-icon.icon-alert {
  flex: none;
}
/* =============== */
/* ==== Error ==== */
/* =============== */
.swal2-icon.swal2-error {
  border-color: #cc2c29;
  color: #cc2c29;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #cc2c29;
}
.title-alert-error {
  font-size: 1.5rem;
  color: #cc2c29;
  text-align: left;
  margin-left: 10px;
}
/* ================= */
/* ==== Success ==== */
/* ================= */
.title-alert-success {
  font-size: 1.5rem;
  color: #6b915c;
  text-align: left;
  margin-left: 10px;
}
.swal2-icon.swal2-success {
  border-color: #6b915c;
  color: #6b915c;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #6b915c;
}
/* ================= */
/* ==== Warning ==== */
/* ================= */
.title-alert-warning {
  font-size: 1.5rem;
  color: #a88721;
  text-align: left;
  margin-left: 10px;
}
.swal2-icon.swal2-warning {
  border-color: #a88721;
  color: #a88721;
}

@media (max-width: 800px) {
  .title-alert-warning {
    text-align: center;
    margin-left: 0px;
  }
  .title-alert-error {
    text-align: center;
    margin-left: 0px;
  }
}
